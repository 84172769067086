/* eslint-disable */

import React, {
	createContext,
	useState,
	useEffect,
	useContext,
	useCallback,
} from 'react'

const ThemeContext = createContext<{ theme: string; toggleTheme: () => void }>({
	theme: 'light',
	toggleTheme: () => { },
})

export const useTheme = () => useContext(ThemeContext)

const ThemeProviderComponent: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [theme, setTheme] = useState<string>('dark') // Set the initial theme to 'dark'

	useEffect(() => {
		const savedTheme = localStorage.getItem('theme')
		if (savedTheme) {
			setTheme(savedTheme)
		}
	}, [])

	useEffect(() => {
		if (theme === 'dark') {
			document.body.classList.add('dark')
		} else {
			document.body.classList.remove('dark')
		}
		localStorage.setItem('theme', theme)
	}, [theme])

	const toggleTheme = useCallback(() => {
		// Toggle the theme between "light" and "dark"
		const newTheme = theme === 'light' ? 'dark' : 'light'
		setTheme(newTheme)
	}, [theme])

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	)
}

export default ThemeProviderComponent

